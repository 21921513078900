export default {
    uyghur: {
        displayName: "Uyghur",
        name: "باش بەت",
        localeName: "ug",
        dateFormat: "ug-cn",
        cssFile: "./assets/css/ug.css",
        fonts: {
            AlpidaUnicodeSystem: {
                italics: "alpdunisys.ttf",
                normal: "alpdunisys.ttf",
            },
        },
        services: [
            {
                title: "Main service",
                url: "/uyghur/appcats/main/app_main_rss.xml",
                categories: [
                    {url: "/uyghur/appcats/cat1/app_rss.xml"},
                    {url: "/uyghur/appcats/cat2/app_rss.xml"},
                    {url: "/uyghur/appcats/cat3/app_rss.xml"},
                    {url: "/uyghur/appcats/cat4/app_rss.xml"},
                    {url: "/uyghur/appcats/cat5/app_rss.xml"},
                    {url: "/uyghur/appcats/cat6/app_rss.xml"},
                ],
                audioFeedUrl: [
                    "https://feeds.acast.com/public/shows/66e1c907f684e0b759a4d00c",
                    "https://feeds.acast.com/public/shows/66e1c7f99a7dcc160e59563f",
                    "https://feeds.acast.com/public/shows/66cdf760cadc382a6bf7c68c",
                ],
            },
        ],
        isRTL: true,
        contactEmail: "contact@rfa.org",
        contactPhone: "1 (202) 530-4900",
        contactWeb: "https://www.rfa.org/about/info/contact.html",
    },
    uyghur_latin: {
        displayName: "Uyghur (latin encoding)",
        name: "Uyghurche",
        localeName: "ug_la",
        dateFormat: "ug-latin",
        cssFile: "./assets/css/default.css",
        services: [
            {
                title: "Main service",
                url: "/uyghur/appcats/main/app_main_rss.xml?encoding=latin",
                categories: [
                    {url: "/uyghur/appcats/cat1/app_rss.xml?encoding=latin"},
                    {url: "/uyghur/appcats/cat2/app_rss.xml?encoding=latin"},
                    {url: "/uyghur/appcats/cat3/app_rss.xml?encoding=latin"},
                    {url: "/uyghur/appcats/cat4/app_rss.xml?encoding=latin"},
                    {url: "/uyghur/appcats/cat5/app_rss.xml?encoding=latin"},
                    {url: "/uyghur/appcats/cat6/app_rss.xml?encoding=latin"},
                ],
                audioFeedUrl: [
                    "https://feeds.acast.com/public/shows/66e1c907f684e0b759a4d00c",
                    "https://feeds.acast.com/public/shows/66e1c7f99a7dcc160e59563f",
                    "https://feeds.acast.com/public/shows/66cdf760cadc382a6bf7c68c",
                ],
            },
        ],
        isRTL: false,
        contactEmail: "contact@rfa.org",
        contactPhone: "1 (202) 530-4900",
        contactWeb: "https://www.rfa.org/about/info/contact.html",
    },
    uyghur_cyrillic: {
        displayName: "Uyghur (cyrillic encoding)",
        name: "Уйғурчә",
        localeName: "ug_cy",
        dateFormat: "ug-cyrillic",
        cssFile: "./assets/css/ug_cyrillic.css",
        fonts: {
            "Open Sans": {
                lightitalics: "OpenSans-LightItalic.ttf",
                light: "OpenSans-Light.ttf",
                italics: "OpenSans-Italic.ttf",
                bolditalics: "OpenSans-BoldItalic.ttf",
                bold: "OpenSans-Bold.ttf",
                normal: "OpenSans-Regular.ttf",
            },
        },
        services: [
            {
                title: "Main service",
                url: "/uyghur/appcats/main/app_main_rss.xml?encoding=cyrillic",
                categories: [
                    {url: "/uyghur/appcats/cat1/app_rss.xml?encoding=cyrillic"},
                    {url: "/uyghur/appcats/cat2/app_rss.xml?encoding=cyrillic"},
                    {url: "/uyghur/appcats/cat3/app_rss.xml?encoding=cyrillic"},
                    {url: "/uyghur/appcats/cat4/app_rss.xml?encoding=cyrillic"},
                    {url: "/uyghur/appcats/cat5/app_rss.xml?encoding=cyrillic"},
                    {url: "/uyghur/appcats/cat6/app_rss.xml?encoding=cyrillic"},
                ],
                audioFeedUrl:[
                    "https://feeds.acast.com/public/shows/66e1c907f684e0b759a4d00c",
                    "https://feeds.acast.com/public/shows/66e1c7f99a7dcc160e59563f",
                    "https://feeds.acast.com/public/shows/66cdf760cadc382a6bf7c68c",
                ],
            },
        ],
        isRTL: false,
        contactEmail: "contact@rfa.org",
        contactPhone: "1 (202) 530-4900",
        contactWeb: "https://www.rfa.org/about/info/contact.html",
    },
    mandarin: {
        displayName: "Mandarin",
        name: "普通话",
        localeName: "zh_CN",
        dateFormat: "zh-cn",
        cssFile: "./assets/css/cmn.css",
        fonts: {
            "Microsoft YaHei": {
                light: "MicrosoftYaHeiLight.ttf",
                bold: "MicrosoftYaHei-Bold.ttf",
                italics: "MicrosoftYaHei.ttf",
                normal: "MicrosoftYaHei.ttf",
            },
        },
        services: [
            {
                title: "Main service",
                url: "/mandarin/appcats/main/app_main_rss.xml",
                categories: [
                    {url: "/mandarin/appcats/cat1/app_rss.xml"},
                    {url: "/mandarin/appcats/cat2/app_rss.xml"},
                    {url: "/mandarin/appcats/cat3/app_rss.xml"},
                    {url: "/mandarin/appcats/cat4/app_rss.xml"},
                    {url: "/mandarin/appcats/cat5/app_rss.xml"},
                    {url: "/mandarin/appcats/cat6/app_rss.xml"},
                ],
                audioFeedUrl: [
                    "https://feeds.acast.com/public/shows/650885a86e095f00119ee9b1",
                    "https://feeds.acast.com/public/shows/673b860c9f811261045e9272",
                    "https://feeds.acast.com/public/shows/670e8afa95a9a2047541287b",
                    "https://feeds.acast.com/public/shows/65148e6d936e0700115e3cd2",
                    "https://feeds.acast.com/public/shows/6511acf55b90e60011694de7",
                    "https://feeds.acast.com/public/shows/6511accf9a13e00011b3756c",
                    "https://feeds.acast.com/public/shows/6511ac5ed7d4700011687978",
                    "https://feeds.acast.com/public/shows/650c9990d0da1a00129a74ec",
                    "https://feeds.acast.com/public/shows/650c965056e85e0012aa4362",
                    "https://feeds.acast.com/public/shows/650c765d05dd320011d04929",
                    "https://feeds.acast.com/public/shows/650c762c2d1d110010ad813b",
                    "https://feeds.acast.com/public/shows/650c76158c8c2d00102f7788",
                    "https://feeds.acast.com/public/shows/650afc498680f900125f4464",
                    "https://feeds.acast.com/public/shows/650afc338680f900125f408d",
                    "https://feeds.acast.com/public/shows/650885a86e095f00119ee9b1",
                    "https://feeds.acast.com/public/shows/670e8afa95a9a2047541287b",
                    "https://feeds.acast.com/public/shows/67bca477b628e470d30467c1",
                ],
            },
        ],
        isRTL: false,
        contactEmail: "fankui@rfa.org",
        contactPhone: "1 (202) 530-4900",
        contactWeb: "https://www.rfa.org/about/info/contact.html",
    },
    cantonese: {
        displayName: "Cantonese",
        name: "粵語",
        localeName: "zh_HK",
        dateFormat: "zh-hk",
        cssFile: "./assets/css/yue.css",
        fonts: {
            "Noto Sans CJK TC": {
                bold: "NotoSansCJKtc-Bold.otf",
                italics: "NotoSansCJKtc-Regular.otf",
                bolditalics: "NotoSansCJKtc-Bold.otf",
                normal: "NotoSansCJKtc-Regular.otf",
            },
        },
        services: [
            {
                title: "Main service",
                url: "/cantonese/appcats/main/app_main_rss.xml",
                categories: [
                    {url: "/cantonese/appcats/cat1/app_rss.xml"},
                    {url: "/cantonese/appcats/cat2/app_rss.xml"},
                    {url: "/cantonese/appcats/cat3/app_rss.xml"},
                    {url: "/cantonese/appcats/cat4/app_rss.xml"},
                    {url: "/cantonese/appcats/cat5/app_rss.xml"},
                    {url: "/cantonese/appcats/cat6/app_rss.xml"},
                ],
                audioFeedUrl: [
                    "https://feeds.acast.com/public/shows/67531fe5c640aa434ca9d6d3",
                    "https://feeds.acast.com/public/shows/66ec8fe1e72fd4be29bf41e4",
                    "https://feeds.acast.com/public/shows/66eae03fec4ba464d56aaf3d",
                    "https://feeds.acast.com/public/shows/66ce0ebe7badf4b7d8ea1f0d",
                ],
            },
        ],
        isRTL: false,
        contactEmail: "cnews@rfa.org",
        contactPhone: "1 (202) 530-4900",
        contactWeb: "https://www.rfa.org/about/info/contact.html",
    },
    burmese: {
        displayName: "Burmese",
        name: "မြန်မာ",
        localeName: "my",
        dateFormat: "my",
        cssFile: "./assets/css/my_mm.css",
        fonts: {
            Pyidaungsu: {
                bold: "Pyidaungsu-2.5.3_Bold.ttf",
                italics: "Pyidaungsu-2.5.3_Regular.ttf",
                bolditalics: "Pyidaungsu-2.5.3_Bold.ttf",
                normal: "Pyidaungsu-2.5.3_Regular.ttf",
            },
        },
        services: [
            {
                title: "Main service",
                url: "/burmese/appcats/main/app_main_rss.xml",
                categories: [
                    {url: "/burmese/appcats/cat1/app_rss.xml"},
                    {url: "/burmese/appcats/cat2/app_rss.xml"},
                    {url: "/burmese/appcats/cat3/app_rss.xml"},
                    {url: "/burmese/appcats/cat4/app_rss.xml"},
                    {url: "/burmese/appcats/cat5/app_rss.xml"},
                ],
                audioFeedUrl: [
                    "https://feeds.acast.com/public/shows/66c76ca90f3233b2d39535d4",
                    "https://feeds.acast.com/public/shows/66bf67c94a224df83a02a6da",
                    "https://feeds.acast.com/public/shows/66bf672e844d445153cf6926",
                    "https://feeds.acast.com/public/shows/66bf670b328d26fd6c0f972b",
                    "https://feeds.acast.com/public/shows/66bf66c0844d445153cf359a",
                    "https://feeds.acast.com/public/shows/66bf66a3844d445153cf2eb9",
                    "https://feeds.acast.com/public/shows/66991bea3bbdd745dcd04c49",
                ],

            },
        ],
        isRTL: false,
        contactEmail: "burweb@rfa.org",
        contactPhone: "1 (202) 530-4900",
        contactWeb: "https://www.rfa.org/about/info/contact.html",
    },
    lao: {
        displayName: "Lao",
        name: "ລາວ",
        localeName: "lo",
        dateFormat: "lo",
        cssFile: "./assets/css/lao.css",
        fonts: {
            "Saysettha OT": {
                italics: "saysettha.ttf",
                normal: "saysettha.ttf",
            },
        },
        services: [
            {
                title: "Main service",
                url: "/lao/appcats/main/app_main_rss.xml",
                categories: [
                    {url: "/lao/appcats/cat1/app_rss.xml"},
                    {url: "/lao/appcats/cat2/app_rss.xml"},
                    {url: "/lao/appcats/cat3/app_rss.xml"},
                    {url: "/lao/appcats/cat4/app_rss.xml"},
                    {url: "/lao/appcats/cat5/app_rss.xml"},
                ],
                audioFeedUrl: [
                    "https://feeds.acast.com/public/shows/66c37eec4d01ce6daf26b977",
                    "https://feeds.acast.com/public/shows/66c37ed3c657d26e5dfaf354",
                ],
            },
        ],
        isRTL: false,
        contactEmail: "laoweb@rfa.org",
        contactPhone: "1 (202) 530-4900",
        contactWeb: "https://www.rfa.org/about/info/contact.html",
    },
    korean: {
        displayName: "Korean",
        name: "한국어",
        localeName: "ko",
        dateFormat: "ko",
        cssFile: "./assets/css/kor.css",
        fonts: {
            NotoSansKR: {
                italics: "NotoSansKR-Regular.otf",
                bold: "NotoSansKR-Bold.otf",
                bolditalics: "NotoSansKR-Bold.otf",
                normal: "NotoSansKR-Regular.otf",
            },
        },
        services: [
            {
                title: "Main service",
                url: "/korean/appcats/main/app_main_rss.xml",
                categories: [
                    {url: "/korean/appcats/cat1/app_rss.xml"},
                    {url: "/korean/appcats/cat2/app_rss.xml"},
                    {url: "/korean/appcats/cat3/app_rss.xml"},
                    {url: "/korean/appcats/cat4/app_rss.xml"},
                    {url: "/korean/appcats/cat5/app_rss.xml"},
                ],
                audioFeedUrl: [
                    "https://feeds.acast.com/public/shows/67c86ae2fc5f88b98d40a3e5",
                    "https://feeds.acast.com/public/shows/6793c28ede7c597e47860ffb",
                    "https://feeds.acast.com/public/shows/66e0515bf684e0b759379600",
                    "https://feeds.acast.com/public/shows/66ce0e71732876ca2dc57718",
                    "https://feeds.acast.com/public/shows/66ce0ceb732876ca2dc52444",
                    "https://feeds.acast.com/public/shows/66ce0befab3deb839d8071d2",
                    "https://feeds.acast.com/public/shows/66cdff947badf4b7d8e5c4cd",
                    "https://feeds.acast.com/public/shows/66cdff80407cbc460961f901",
                    "https://feeds.acast.com/public/shows/66cdff61407cbc460961eb0f",
                    "https://feeds.acast.com/public/shows/66cdff27732876ca2dc13868",
                ],
            },
        ],

        isRTL: false,
        contactEmail: "nk@rfa.org",
        contactPhone: "1 (202) 530-4900",
        contactWeb: "https://www.rfa.org/about/info/contact.html",
    },
    khmer: {
        displayName: "Khmer",
        name: "ខ្មែរ",
        localeName: "km",
        dateFormat: "km",
        cssFile: "./assets/css/khm.css",
        fonts: {
            Hanuman: {
                black: "Hanuman-Black.ttf",
                bolditalics: "Hanuman-Bold.ttf",
                bold: "Hanuman-Bold.ttf",
                italics: "Hanuman-Regular.ttf",
                normal: "Hanuman-Regular.ttf",
            },
        },
        services: [
            {
                title: "Main service",
                url: "/khmer/appcats/main/app_main_rss.xml",
                categories: [
                    {url: "/khmer/appcats/cat1/app_rss.xml"},
                    {url: "/khmer/appcats/cat2/app_rss.xml"},
                    {url: "/khmer/appcats/cat3/app_rss.xml"},
                    {url: "/khmer/appcats/cat4/app_rss.xml"},
                    {url: "/khmer/appcats/cat5/app_rss.xml"},
                    {url: "/khmer/appcats/cat6/app_rss.xml"},
                ],
                audioFeedUrl: [
                    "https://feeds.acast.com/public/shows/67b4b0885033e3e15aa31615",
                    "https://feeds.acast.com/public/shows/670e5c90c9fa3ffd9e8bce26",
                ],
            },
        ],
        isRTL: false,
        contactEmail: "contact@rfa.org",
        contactPhone: "1 (202) 530-4900",
        contactWeb: "https://www.rfa.org/about/info/contact.html",
    },
    vietnamese: {
        displayName: "Vietnamese",
        name: "Tiếng Việt",
        localeName: "vi",
        dateFormat: "vi",
        cssFile: "./assets/css/vi_vn.css",
        services: [
            {
                title: "Main service",
                url: "/vietnamese/appcats/main/app_main_rss.xml",
                categories: [
                    {url: "/vietnamese/appcats/cat1/app_rss.xml"},
                    {url: "/vietnamese/appcats/cat2/app_rss.xml"},
                    {url: "/vietnamese/appcats/cat3/app_rss.xml"},
                    {url: "/vietnamese/appcats/cat4/app_rss.xml"},
                    {url: "/vietnamese/appcats/cat5/app_rss.xml"},
                ],
                audioFeedUrl: [
                    "https://feeds.acast.com/public/shows/66c4e7c44bc29b3432041b80",
                    "https://feeds.acast.com/public/shows/66c4c06dc297ce076de5b7ec",
                ],
            },
        ],
        fonts: {
            "Be Vietnam Pro": {
                bold: "BeVietnamPro-Bold.ttf",
                bolditalics: "BeVietnamPro-BoldItalic.ttf",
                italics: "BeVietnamPro-Italic.ttf",
                normal: "BeVietnamPro-Regular.ttf",
            },
        },
        isRTL: false,
        contactEmail: "vietweb@rfa.org",
        contactPhone: "1 (202) 530-4900",
        contactWeb: "https://www.rfa.org/about/info/contact.html",
    },
    tibetan: {
        displayName: "Tibetan",
        name: "བོད་སྐད།",
        localeName: "bo",
        dateFormat: "bo",
        cssFile: "./assets/css/bo.css",
        fonts: {
            "Monlam Uni Ouchan2": {
                bold: "Monlam_Uni_Ochan2.ttf",
                italics: "Monlam_Uni_Ochan2.ttf",
                normal: "Monlam_Uni_Ochan2.ttf",
            },
        },
        services: [
            {
                title: "Main service",
                url: "/tibetan/appcats/main/app_main_rss.xml",
                categories: [
                    {url: "/tibetan/appcats/cat1/app_rss.xml"},
                    {url: "/tibetan/appcats/cat2/app_rss.xml"},
                    {url: "/tibetan/appcats/cat3/app_rss.xml"},
                    {url: "/tibetan/appcats/cat4/app_rss.xml"},
                    {url: "/tibetan/appcats/cat5/app_rss.xml"},
                ],
                audioFeedUrl: [
                    "https://feeds.acast.com/public/shows/66bf797e47151aa1d3dcbf4a",
                    "https://feeds.acast.com/public/shows/66bf79328d5b0e0994c7ff55",
                    "https://feeds.acast.com/public/shows/66bf7919328d26fd6c14a7cd",
                    "https://feeds.acast.com/public/shows/66bf79038d5b0e0994c7efd1",
                    "https://feeds.acast.com/public/shows/6682f9acce082b11854a95a0",
                ],
            },
        ],
        isRTL: false,
        contactEmail: "tibetnews@rfa.org",
        contactPhone: "1 (202) 530-4900",
        contactWeb: "https://www.rfa.org/about/info/contact.html",
    },
    english: {
        displayName: "English",
        name: "English",
        localeName: "en",
        dateFormat: "en",
        cssFile: "./assets/css/default.css",
        fonts: {
            MerriweatherSans: {
                light: "MerriweatherSans-Light.ttf",
                italics: "MerriweatherSans-Italic.ttf",
                bolditalics: "MerriweatherSans-BoldItalic.ttf",
                bold: "MerriweatherSans-Bold.ttf",
                extrabold: "MerriweatherSans-ExtraBold.ttf",
                extrabolditalics: "MerriweatherSans-ExtraBoldItalic.ttf",
                normal: "MerriweatherSans-Regular.ttf",
            },
            NotoSerif: {
                normal: "NotoSerif-Regular.ttf",
                bold: "NotoSerif-Bold.ttf",
            },
            RobotSlab: {
                normal: "RobotoSlab-Regular.ttf",
            },
        },
        services: [
            {
                title: "Main service",
                url: "/english/appcats/main/app_main_rss.xml",
                categories: [
                    {url: "/english/appcats/cat1/app_rss.xml"},
                    {url: "/english/appcats/cat2/app_rss.xml"},
                    {url: "/english/appcats/cat3/app_rss.xml"},
                    {url: "/english/appcats/cat4/app_rss.xml"},
                    {url: "/english/appcats/cat5/app_rss.xml"},
                    {url: "/english/appcats/cat6/app_rss.xml"},
                ],
                audioFeedUrl: [
                    "https://feeds.acast.com/public/shows/659d85daff04980015ef8960",
                    "https://feeds.acast.com/public/shows/656a1ff734b8d300121a6aa5",
                    "https://feeds.acast.com/public/shows/64babec2f6632e0011771a99",
                    "https://feeds.acast.com/public/shows/6697f2ad7c66852600633372",
                    "https://feeds.acast.com/public/shows/6697f5c6c86d4486beb5421d",
                ],
            },
        ],
        isRTL: false,
        contactEmail: "contact@rfa.org",
        contactPhone: "1 (202) 530-4900",
        contactWeb: "https://www.rfa.org/about/info/contact.html",
    },
};
